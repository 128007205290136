import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, DonationSuccess } from '@components';

const { Banner } = Banners;

const Index = () => (
  <Layout bannerChildren={<Banner title='Successfully Donated' />}>
    <SEO title='SUCCESSFUL DONATION' donation='Successful donation to The Africa I Know,Inc.' />
    <article id='index-article'>
      <DonationSuccess />
    </article>
  </Layout>
);

export default Index;
